import React, { KeyboardEventHandler, useContext, useState } from 'react';

import { CheckOutlined, CloseOutlined, DeleteOutlined, SendOutlined } from '@ant-design/icons';
import { Modal, Form, Button, List, Input, Typography, Divider, Comment, Tooltip, Tag, Space } from 'antd';
import moment from 'moment';

import { GlobalContext } from '../context/GlobalContextProvider';
import { usePaper } from '../hooks';

const getPaperStatusTag = (status?: Papers.PAPER_STATUS) => {
    if (status === 'approved') return <Tag color="green">Approved</Tag>;
    else if (status === 'rework') return <Tag color="red">Rework</Tag>;
    else if (status === 'review-pending') return <Tag color="blue">Review Pending</Tag>;
    else return <Tag>Not Marked for Review</Tag>;
};

const sortEvents = (event1: Papers.PaperEvent, event2: Papers.PaperEvent) => {
    const eventTime1 = new Date(event1.triggeredAt).getTime();
    const eventTime2 = new Date(event2.triggeredAt).getTime();

    if (eventTime1 < eventTime2) return 1;
    else return -1;
};

export const ReviewPaperModal = () => {
    const context = useContext(GlobalContext);
    const { addComment, paperLoading, changeStatus } = usePaper();
    const [commentForm] = Form.useForm();

    const ownerId =
        typeof context.reviewPaper?.owner === 'string' ? context.reviewPaper.owner : context.reviewPaper?.owner?._id;
    const loggedInUserId = context.loggedInUser?._id;
    const isPaperOwner = ownerId === loggedInUserId;

    return (
        <Modal
            visible={!!context.reviewPaper}
            title="Review Paper"
            closable={true}
            footer={
                <>
                    <Form
                        layout="vertical"
                        onFinish={values => {
                            addComment({ paperId: context.reviewPaper?._id!, comment: values.comment });
                            commentForm.resetFields();
                        }}
                        form={commentForm}
                    >
                        <Form.Item name="comment" style={{ width: '100%' }}>
                            <Input.TextArea placeholder="Add your comments here" rows={3} />
                        </Form.Item>
                    </Form>
                    <Button type="primary" onClick={() => commentForm.submit()} loading={paperLoading} block>
                        Comment
                    </Button>
                </>
            }
            onCancel={() => context.setReviewPaper(undefined)}
        >
            <Space align="center">
                <Typography.Text>Status: {getPaperStatusTag(context.reviewPaper?.status)}</Typography.Text>
                {!isPaperOwner && (
                    <>
                        <Button
                            type="link"
                            icon={<CheckOutlined />}
                            loading={paperLoading}
                            block
                            onClick={() => changeStatus({ paperId: context.reviewPaper?._id!, newStatus: 'approved' })}
                        >
                            Approve
                        </Button>
                        <Button
                            type="link"
                            danger
                            icon={<CloseOutlined />}
                            loading={paperLoading}
                            block
                            onClick={() => changeStatus({ paperId: context.reviewPaper?._id!, newStatus: 'rework' })}
                        >
                            Send for Rework
                        </Button>
                    </>
                )}
                {isPaperOwner && (
                    <Button
                        type="link"
                        icon={<SendOutlined />}
                        loading={paperLoading}
                        block
                        onClick={() =>
                            changeStatus({ paperId: context.reviewPaper?._id!, newStatus: 'review-pending' })
                        }
                    >
                        Send for Review
                    </Button>
                )}
            </Space>
            <Divider />
            <List
                size="small"
                loading={paperLoading}
                dataSource={context.reviewPaper?.events?.sort(sortEvents)}
                style={{ maxHeight: '40vh', overflowY: 'scroll' }}
                renderItem={event => (
                    <List.Item>
                        {event.type === 'comment' && (
                            <Comment
                                author={
                                    <a>
                                        {event.triggeredBy.firstName || ''} {event.triggeredBy.lastName || ''}
                                    </a>
                                }
                                content={<p>{event.data?.comment}</p>}
                                datetime={
                                    <Tooltip title={new Date(event.triggeredAt).toString()}>
                                        <span>{moment(event.triggeredAt).fromNow()}</span>
                                    </Tooltip>
                                }
                            />
                        )}

                        {event.type === 'status-change' && (
                            <div style={{ textAlign: 'center', width: '100%' }}>
                                <Typography.Text type="secondary" style={{ fontSize: '12px' }}>
                                    Paper status changed to {getPaperStatusTag(event.data.newStatus)} by{' '}
                                    {event.triggeredBy.firstName || ''} {event.triggeredBy.lastName || ''},{' '}
                                    <Tooltip title={new Date(event.triggeredAt).toString()}>
                                        <span>{moment(event.triggeredAt).fromNow()}</span>
                                    </Tooltip>
                                </Typography.Text>
                            </div>
                        )}
                    </List.Item>
                )}
            />
        </Modal>
    );
};

export default ReviewPaperModal;
