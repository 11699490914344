import React, { useContext, useEffect, useState } from 'react';

import {
    CheckCircleFilled,
    CheckCircleOutlined,
    CheckOutlined,
    CommentOutlined,
    DeleteOutlined,
    EditOutlined,
    FileSearchOutlined,
    InfoOutlined,
    PlusOutlined,
    SearchOutlined,
    SendOutlined,
    ShareAltOutlined,
} from '@ant-design/icons';
import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    Row,
    Select,
    Space,
    Table,
    TablePaginationConfig,
    Tag,
    Tooltip,
    Typography,
} from 'antd';
import { navigate } from 'gatsby';
import moment from 'moment';
import { number } from 'prop-types';
import { useCookies } from 'react-cookie';
import store from 'store';

import Head from '../components/Head';
import MainLayout from '../components/MainLayout';
import ReviewPaperModal from '../components/ReviewPaperModal';
import SharePaperModal from '../components/SharePaperModal';
import { GlobalContext } from '../context/GlobalContextProvider';
import { useAuth, usePaper } from '../hooks';

export const IndexPage = () => {
    const [addPaperDrawerVisibility, setAddPaperDrawerVisibility] = useState(false);
    const [addPaperForm] = Form.useForm();
    const [cookies, setCookie, removeCookie] = useCookies(['authToken', 'user', 'refreshToken']);

    const {
        papers,
        paperLoading,
        createPaper,
        deletePaper,
        refreshPapers,
        setActivePaper,
        sharePaperWithEmail,
        removePaperSharedWithEmail,
    } = usePaper();
    const context = useContext(GlobalContext);
    const { localLoginCheck } = useAuth();
    const isBrowser = () => typeof window !== 'undefined';
    const user = store.get('user') as Users.User;

    const params = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : { get: () => {} };
    const show = params.get('show');
    const showSharedPapersOnly = show === 'shared';
    if (showSharedPapersOnly) {
        context.setPaperListType('shared');
    } else {
        context.setPaperListType('own');
    }

    useEffect(() => {
        refreshPapers();
    }, [context.paperPagination.refresh]);

    useEffect(() => {
        context.setPaperPagination(pagination => ({ ...pagination, skip: 0, refresh: !pagination.refresh }));
    }, [context.paperSearch, context.paperListType]);
    useEffect(() => {
        if (papers.length && context.sharePaper) {
            const paperToShare = papers.find(paper => paper._id === context.sharePaper?._id);
            context.setSharePaper(paperToShare);
        }
    }, [papers]);

    useEffect(() => {
        refreshPapers();
    }, []);

    useEffect(() => {
        localLoginCheck();
    }, []);

    isBrowser() &&
        useEffect(() => {
            const path = window.location.pathname;
            if (path === '/') {
                context.setActivePaper(undefined);
                store.set('activePaper', undefined);
                context.setHtml('');
                context.setTitlePage({});
                context.setEditorLoaded(false);
            }
        }, [window.location.pathname]);

    const getPaperStatusTag = (status: Papers.PAPER_STATUS) => {
        if (status === 'approved') return <Tag color="green">Approved</Tag>;
        else if (status === 'rework') return <Tag color="red">Rework</Tag>;
        else if (status === 'review-pending') return <Tag color="blue">Review Pending</Tag>;
        else return <Tag>Not Marked for Review</Tag>;
    };

    const getPaperTypeTag = (type: Papers.Paper['type']) => {
        if (type === 'discussion') return <Tag color="blue">Discussion</Tag>;
        else if (type === 'research') return <Tag color="red">Research</Tag>;
        else if (type === 'assignment') return <Tag color="green">Assignment</Tag>;
        else return <Tag>Old Version</Tag>;
    };

    const columns = [
        {
            title: 'Question',
            dataIndex: 'question',
            key: 'question',
            width: '30%',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: '20%',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: '10%',
            align: 'center' as const,
            render: getPaperTypeTag,
        },
        // {
        //     title: 'Owner',
        //     dataIndex: 'owner',
        //     key: 'owner',
        //     width: '10%',
        //     align: 'center' as const,
        //     render: (owner: Users.User, paper: Papers.Paper) => (
        //         <Tooltip
        //             placement="top"
        //             title={`${typeof paper.owner === 'string' ? paper.owner : paper.owner?.emailId}`}
        //         >
        //             {`${owner?.firstName || ''} ${owner?.lastName || ''}`}
        //         </Tooltip>
        //     ),
        // },
        // {
        //     title: 'Status',
        //     dataIndex: 'status',
        //     key: 'status',
        //     width: '10%',
        //     align: 'center' as const,
        //     render: getPaperStatusTag,
        // },
        {
            title: 'Created On',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt: string) => moment(createdAt).format('lll'),
            width: '10%',
        },
        {
            key: 'actions',
            title: 'Actions',
            width: '10%',
            align: 'center' as const,
            dataIndex: '_id',
            fixed: 'right' as const,
            render: (pid: any, paper: Papers.Paper) => {
                const isShared = paper.sharedWith?.length;
                return (
                    <Space>
                        <Button
                            type="link"
                            icon={<EditOutlined />}
                            onClick={() => {
                                setActivePaper(paper);
                                store.set('activePaper', paper);
                                navigate('/editor');
                            }}
                        />

                        {context.plagarismReports.find(report => {
                            return report.paper_id === paper._id && report.status === 'fulfilled';
                        }) || paper.plagarism_report_url ? (
                            <a href={paper.plagarism_report_url} target="_blank" rel="noreferrer">
                                <FileSearchOutlined />
                            </a>
                        ) : null}

                        {(typeof paper.owner === 'string' ? paper.owner : paper.owner?._id) === user?._id ? (
                            <>
                                {/* <Button
                                    icon={<ShareAltOutlined />}
                                    onClick={() => context.setSharePaper(paper)}
                                    loading={paperLoading}
                                    disabled={
                                        (typeof paper.owner === 'string' ? paper.owner : paper.owner?._id) !== user?._id
                                    }
                                    type={isShared ? 'primary' : 'link'}
                                /> */}
                                <Button
                                    type="link"
                                    icon={<SendOutlined />}
                                    onClick={() => context.setReviewPaper(paper)}
                                    loading={paperLoading}
                                    disabled={!isShared}
                                />
                                <Button
                                    type="link"
                                    icon={<DeleteOutlined />}
                                    danger
                                    onClick={() => deletePaper(pid)}
                                    loading={paperLoading}
                                />
                            </>
                        ) : (
                            <Button
                                type="link"
                                icon={<CommentOutlined />}
                                onClick={() => context.setReviewPaper(paper)}
                                loading={paperLoading}
                            />
                        )}
                    </Space>
                );
            },
        },
    ];

    const pageChange = (pageOptions: TablePaginationConfig) => {
        const current = pageOptions.current || 1;
        context.setPaperPagination(pagination => ({
            ...pagination,
            skip: pagination.limit * (current - 1),
            refresh: !pagination.refresh,
        }));
    };

    return (
        <MainLayout>
            <>
                <Head title="Papers" />
                <Typography.Title level={2}>{showSharedPapersOnly ? 'Shared Papers' : 'My Papers'}</Typography.Title>
                <Typography.Paragraph>
                    {showSharedPapersOnly ? `Manage papers shared with you` : 'Manage your papers'}
                </Typography.Paragraph>
                <Row style={{ marginBottom: '1.5rem' }}>
                    <Col span={12}>
                        <Input
                            placeholder="Filter papers"
                            onChange={e => context.setPaperSearch(e.target.value)}
                            prefix={<SearchOutlined />}
                        />
                    </Col>
                    <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Space>
                            <Button
                                icon={<PlusOutlined />}
                                type="primary"
                                onClick={() => setAddPaperDrawerVisibility(true)}
                            >
                                New Paper
                            </Button>
                        </Space>
                    </Col>
                </Row>
                <Row>
                    <Table
                        pagination={{
                            pageSize: context.paperPagination?.limit || 0,
                            total: context.paperPagination?.total || 0,
                            current: context.paperPagination?.skip / context.paperPagination?.limit + 1,
                            showSizeChanger: false,
                        }}
                        loading={paperLoading}
                        style={{ width: '100%' }}
                        dataSource={papers}
                        rowKey={(record: Papers.Paper) => record._id!}
                        columns={columns}
                        onChange={pageChange}
                    />
                </Row>
                <Drawer
                    visible={addPaperDrawerVisibility}
                    onClose={() => setAddPaperDrawerVisibility(false)}
                    destroyOnClose
                    title="New Paper"
                    footer={
                        <Space>
                            <Button onClick={() => setAddPaperDrawerVisibility(false)}>Cancel</Button>
                            <Button type="primary" onClick={() => addPaperForm.submit()} loading={paperLoading}>
                                Continue
                            </Button>
                        </Space>
                    }
                    footerStyle={{ textAlign: 'right' }}
                >
                    <Form labelCol={{ span: 24 }} form={addPaperForm} onFinish={createPaper}>
                        <Form.Item name="question" label="Question">
                            <Input />
                        </Form.Item>
                        <Form.Item name="title" label="Title">
                            <Input />
                        </Form.Item>
                        <Form.Item name="courseNumberAndName" label="Course Name and Number">
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="type"
                            label="Type"
                            rules={[{ required: true, message: 'Please input type of paper' }]}
                        >
                            <Select>
                                <Select.Option value="research">Research Paper</Select.Option>
                                <Select.Option value="assignment">Assignment</Select.Option>
                                <Select.Option value="discussion">Discussion</Select.Option>
                            </Select>
                        </Form.Item>
                    </Form>
                </Drawer>
                <SharePaperModal />
                <ReviewPaperModal />
            </>
        </MainLayout>
    );
};

export default IndexPage;
